import React from 'react';
import * as GA from 'app/services/google-analytics';

import * as styles from './home.scss';

const quotes = [
  {
    author: 'Ralph Johnson',
    quote: 'Before software can be reusable it first has to be usable.',
  },
  {
    author: 'Albert Einstein',
    quote: 'The significant problems we face cannot be solved by the same level of thinking that created them.',
  },
  {
    author: 'W.Edwards Deming',
    quote: 'It is not enough to do your best: you must KNOW what to do, and THEN do your best.',
  },
  {
    author: 'Jim Highsmith',
    quote: 'The best way to get a project done faster is to start sooner',
  },
  {
    author: 'Bill Gates',
    quote: 'Measuring programming progress by lines of code is like measuring aircraft building progress by weight.',
  },
];

const minHeight = 200;

const Home: React.FunctionComponent<Record<string, unknown>> = () => {
  const ref = React.useRef<HTMLDivElement>();
  const [height, updateHeight] = React.useState(minHeight);
  const index = Math.floor(Math.random() * quotes.length);

  React.useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    GA.sendPageView('home');

    /** Stretch table height to the remaining viewport height. */
    updateHeight(Math.max(window.innerHeight - ref.current.getBoundingClientRect().top - 20, minHeight));
  }, [ref.current]);

  return (
    <div className="row align-content-center" style={{ height }} ref={ref}>
      <div className="col-12">
        <h2 className={styles.quote}>{quotes[index].quote}</h2>
        <p className={styles.author}>{quotes[index].author}</p>
      </div>
    </div>
  );
};

export default Home;
